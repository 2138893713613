
export default [
  {
    img: 'img/theme/bootstrap.jpg',
    imgWrk: 'img/theme/team-3.jpg',
    title: 'Argon Design System',
    indexer: '0x0000000000333f28d3a20605c702f66c3143cccf',
    indexerSort: '0x000000-3143cccf',
    budget: '$2500 USD',
    status: 'pending',
    statusType: 'warning',
    completion: 60,
    createdAt: '10/09/2018',
  },
  {
    img: 'img/theme/angular.jpg',
    imgWrk: 'img/theme/team-2.jpg',
    title: 'Angular Now UI Kit PRO',
    indexer: '0x000006eee6e39015cb523aebdd4d0b1855aba682',
    indexerSort: '0x00-4d0b1855aba682',
    budget: '$1800 USD',
    status: 'completed',
    statusType: 'success',
    completion: 100,
    createdAt: '10/09/2018',
  },
  {
    img: 'img/theme/sketch.jpg',
    imgWrk: 'img/theme/team-1.jpg',
    title: 'Black Dashboard',
    indexer: '0x0003ca24e19c30db588aabb81d55bfcec6e196c4',
    indexerSort: '0x00-c6e196c4',
    budget: '$3150 USD',
    status: 'delayed',
    statusType: 'danger',
    completion: 72,
    createdAt: '10/09/2018',
  },
  {
    img: 'img/theme/react.jpg',
    imgWrk: 'img/theme/team-2.jpg',
    title: 'React Material Dashboard',
    indexer: '0x000f4432a40560bbff1b581a8b7aded8dab80026',
    indexerSort: '0x000f44-7aded8dab80026',
    budget: '$4400 USD',
    status: 'on schedule',
    statusType: 'info',
    completion: 90,
    createdAt: '10/09/2018',
  },
  {
    img: 'img/theme/vue.jpg',
    imgWrk: 'img/theme/team-1.jpg',
    title: 'Vue Paper UI Kit PRO',
    indexer: '0x004cb8caf98bd224a416063ae87e22051ea2b618',
    indexerSort: '0x004cb8-7e22051ea2b618',
    budget: '$2200 USD',
    status: 'completed',
    statusType: 'success',
    completion: 100,
    createdAt: '12/09/2017',
  },
  {
    img: 'img/theme/bootstrap.jpg',
    imgWrk: 'img/theme/team-3.jpg',
    title: 'Argon Design System',
    indexer: '0x00619159cbc5942cd1ac7c9fb34958a01b35151c',
    indexerSort: '0x00619-958a01b35151c',
    budget: '$2500 USD',
    status: 'pending',
    statusType: 'warning',
    completion: 60,
    createdAt: '10/09/2019',
  },
  {
    img: 'img/theme/angular.jpg',
    imgWrk: 'img/theme/team-2.jpg',
    title: 'Angular Now UI Kit PRO',
    indexer: '0x00619159cbc5942cd1ac7c9fb34958a01b35151c',
    indexerSort: '0x00619-a01b35151c',
    budget: '$1800 USD',
    status: 'completed',
    statusType: 'success',
    completion: 100,
    createdAt: '10/10/2018',
  },
  {
    img: 'img/theme/sketch.jpg',
    imgWrk: 'img/theme/team-3.jpg',
    title: 'Black Dashboard',
    indexer: '0x006a914e08d57b9cccdabc5c45ea6b42230cb419',
    indexerSort: '0x006a914e08-b42230cb419',
    budget: '$3150 USD',
    status: 'delayed',
    statusType: 'danger',
    completion: 72,
    createdAt: '11/09/2020',
  },
  {
    img: 'img/theme/vue.jpg',
    imgWrk: 'img/theme/team-1.jpg',
    title: 'Vue Paper UI Kit PRO',
    indexer: '0x003a73be72eac324ca9a30862c9423a4f92784c5',
    indexerSort: '0x003a73b-3a4f92784c5',
    budget: '$2200 USD',
    status: 'completed',
    statusType: 'success',
    completion: 100,
    createdAt: '10/01/2018',
  }
]
